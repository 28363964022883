/*download album all styles*/

.download-album-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  /* border-radius: 8px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Arial", sans-serif;
  padding-bottom: 0 !important;
}
.download-guestbook{
  margin-top: 12px;
}
.download-album-container h2 {
  font-family: "Libre Baskerville", serif;
  letter-spacing: 5px;
  color: #333;
  text-transform: uppercase;
  font-size: 24px; /* Default size */
  font-weight: 400;
  color: #dcc8b4 !important;
  margin-bottom: 15px;
  border-bottom: 3px solid #dcc8b4;
}

.download-album-container input {
  padding: 10px 0 10px 10px; /* Padding: top right bottom left */
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 22px) !important;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-size: 16px;
  color: #666;
}

.download-album-container button {
  padding: 10px 20px;
  background: linear-gradient(135deg, #dcc8b4, #b89d89);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-album-container button:hover {
  background: linear-gradient(135deg, #b89d89, #dcc8b4);
  transform: scale(1.02); /* Slight zoom effect */
}

.subtitle {
  text-align: left;
  font-size: 13px !important;
}

.download-album-container p {
  margin: 10px 0;
  color: #555;
  font-size: 14px;
}

.download-album-container button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.download-album-container button:hover:not(:disabled) {
  background-color: #0056b3;
}

.error-message {
  color: #d9534f !important;
  font-size: 14px;
}

.success-message {
  color: #28a745 !important;
  font-size: 14px;
}

footer.support-footer {
  margin-top: 30px;
  font-size: 14px;
  color: #666;
  border-top: 3px solid #dcc8b4;
}

footer.support-footer a {
  color: #007bff;
  text-decoration: none;
}

footer.support-footer a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 600px) {
  .download-album-container {
    padding: 15px;
  }

  .download-album-container h2 {
    font-size: 22px;
  }

  .download-album-container input {
    font-size: 14px;
  }

  .download-album-container button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.recommendation {
  font-size: 13px !important;
}
