/* Footer.css */


.Album-footer {
    background-color: var(--body-background-color); /* Light background for contrast */
    /* padding: 20px; */
    text-align: center;
    font-family: 'Arial', sans-serif;
    border-top: 2px solid var(--primary-border-color); /* Top border to separate footer */
    margin-top: 10px;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .footer-social {
    display: flex;
    align-items: center;
    gap: 20px; /* Space between icons */
  }
  
  .footer-email a, .footer-social a {
    color: #333; /* Dark text for icons */
    transition: color 0.3s ease;
  }
  
  .footer-email a:hover, .footer-social a:hover {
    color: var(--hover-gradient-color-start); /* Hover color for both icons */
  }
  
  .footer-rights {
    margin-top: 10px;
    font-size: 14px;
    color: #777; /* Lighter color for rights text */
    color: var(--ad-text-color); /* Lighter color for rights text */
  }
  
  .footer-rights-ad {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
    color: var(--ad-text-color); /* Lighter color for rights text */
  }
  
  .instagram-icon, .email-icon {
    font-size: 24px; /* Adjust icon size */
  }

  .instagram-icon{
    color: var(--icon-color-reload); /* Instagram color */
    transition: color 0.3s ease;
  }

  .email-icon{
    color: var(--icon-color-reload); /* Instagram color */
    transition: color 0.3s ease;
  }

  
  @media (max-width: 768px) {
    .Album-footer {
      padding: 15px;
    }
  
    .instagram-icon, .email-icon {
      font-size: 20px;
    }
  }
  
  .footer-rights-ad a {
    color: inherit; /* Un color atractivo para el enlace, como tomate */
    text-decoration: none; /* Quitar el subrayado */
   
    transition: color 0.3s ease; /* Suavizar la transición de color */
  }