/* 
body{
  background-color: #f6f4f2;
} */
.photograph-upload-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  /* border-radius: 8px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* text-align: center; */
  font-family: "Arial", sans-serif;
}

.photograph-upload-container h2 {
  font-family: "Libre Baskerville", serif;
  letter-spacing: 5px;
  color: #333;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  color: #dcc8b4;
  border-bottom: 3px solid #dcc8b4;
  margin-bottom: 15px;
}

.photograph-upload-container input[type="text"],
.photograph-upload-container input[type="file"],
.photograph-upload-container textarea {
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: calc(100% - 22px);
  font-size: 16px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-size: 16px;
  color:#777;
}

input[type="text"]::placeholder {
  color: #777; 
}


textarea {
  color: #777;
  border: 1px solid var(--input-border-color) !important;
}

textarea::placeholder {
 color: #777;
}

label{
  color: #777;
}

.photograph-upload-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  background: linear-gradient(
    135deg,
    #dcc8b4,
    #b89d89
  );
}



.photograph-upload-container button:hover {
  background: linear-gradient(
    135deg,
    #b89d89,
    #dcc8b4
  );
  transform: scale(1.02); /* Slight zoom effect */
}



.photograph-upload-container p {
  margin: 10px 0;
  color: #555;
  font-size: 14px;
}

.photograph-upload-container button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.error-message {
  color: #d9534f;
  font-size: 14px;
}

.success-message {
  color: #28a745;
  font-size: 14px;
  text-align: center;
}

footer.support-footer {
  margin-top: 30px;
  font-size: 14px;
  color: #666;
  border-top: 3px solid #dcc8b4;
}

footer.support-footer a {
  color: #007bff;
  text-decoration: none;
}

footer.support-footer a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 600px) {
  .photograph-upload-container {
    padding: 15px;
  }

  .photograph-upload-container h2 {
    font-size: 22px;
  }

  .photograph-upload-container input,
  .photograph-upload-container textarea {
    font-size: 14px;
  }

  .photograph-upload-container button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
.success-message {
  font-size: 1.2em; /* Adjust font size as needed */
  color: green; /* Change color as desired */
  margin: 10px 0; /* Space around the success message */
}

.success-message a {
  display: inline-block; /* Ensures it behaves like a block element */
  margin-top: 5px; /* Space above the link */
  text-decoration: underline; /* Underline the link for emphasis */
  color: blue; /* Change link color as desired */
}

.success-message a:hover {
  color: darkblue; /* Change color on hover */
}
