:root {
  /*already implemented*/
  /* --body-background-color: #f6f4f2; 
  --app-background-color: #fff;  */
  --header-color: #dcc8b4; /* golden color for h1-h6 */
  /* --admin-link-background-color: #dcc8b4;  */
  /*already implemented*/
}
/* General container for the list of events */
.list-events-container {
  padding: 20px;
  width: 100%;
}

/* Table container for overflow handling */
.table-container {
  overflow-x: auto;
  margin-top: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}

/* Styling for the events table */
table.table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

table.table th,
table.table td {
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

table.table th {
  background-color: #dcc8b4;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

table.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.table tr:hover {
  background-color: #ddd;
}

/* Links in the table */
.table-link {
  color: #1a73e8;
  text-decoration: none;
}

.table-link:hover {
  text-decoration: underline;
}

/* Footer styling */
.support-footer {
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
  color: #666;
  padding: 20px 0;
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
}

.support-footer a {
  color: #1a73e8;
  text-decoration: none;
}

.support-footer a:hover {
  text-decoration: underline;
}

