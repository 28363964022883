
/* Style for the Scroll to Top button */
.scrollToTop {
    display: none; /* Hidden by default, shown based on JavaScript logic */
    position: fixed;
    opacity: 0;
    bottom: 110px;
    right: 30px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    /* padding: 10px 15px; */
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-size: 18px;
    transition: opacity 0.6s ease, transform 2s ease !important; /* Longer transition for opacity and transform */
    width: 40px;
    height: 40px;
  }
  
  /* Style when the button is visible */
  .scrollToTop.show {
    display: flex;
    justify-content: center;
  align-items: center;
    opacity: 1;
  }
  
  /* COPIA DEL APP CSS*/
  
  @media (max-width: 768px) {
    .scrollToTop {
      bottom: 91px;
      width: 35px;
    height: 35px;
    }
  }
  