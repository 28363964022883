
.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between buttons */
    margin: 20px auto; /* Margin to add spacing around pagination */
    width: calc(100% - 22px); /* Total width to fit within the available space */
  }

  
  .pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px auto;
    margin: 0 auto;
  }
  
  .pagination-button {
    background-color: #dcc8b4;
    color: white;
    border: none;
    border-radius: 50%; /* Circular buttons */
    width: 40px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  



  .pagination-button i {
    pointer-events: none; /* Prevent interfering with button clicks */
  }
  
  .pagination-button:disabled {
    background-color: #d6d6d6; /* Disabled color */
    cursor: not-allowed;
  }

 

  
  .pagination-button:hover:not(:disabled) {
    background-color: #e5c100; /* Darker golden color on hover */
  }
  
  .pagination-ellipsis {
    font-size: 24px;
    color: #d6d6d6;
    padding: 0 10px;
  }

  /* Progress bar container */
.pagination-progress {
  position: relative;
  width: 200px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Progress fill based on page percentage */
.pagination-progress-fill {
  background-color: #dcc8b4;
  height: 100%;
  border-radius: 10px;
  transition: width 0.3s ease;
}

/* Text inside the progress bar */
.pagination-page-info {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  pointer-events: none; /* Prevents interaction with the text */
}
  
  @media (max-width: 600px) {
    .pagination-controls {
      flex-direction: row; /* Keep the layout in a row for smaller screens */
      gap: 5px;
    }
  
    .pagination-button {
      width: 35px;
      height: 35px; /* Adjust size for smaller screens */
    }
  
    .pagination-ellipsis {
      font-size: 20px;
      padding: 0 5px;
    }
    .pagination-page-info {
      font-size: 16px;
      padding: 0 5px; /* Adjust padding for smaller screens */
    }
    .pagination-progress {
      width: 150px;
      height: 15px;
    }
  
    .pagination-page-info {
      font-size: 12px;
    }
  }
  
  .pagination-progress-circle {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 0 20px;
   
    
  }
  
  .pagination-progress-circle circle {
    transition: stroke-dashoffset 0.4s ease-in-out;

  }
  
  .pagination-progress-circle text {
    font-weight: bold;
    fill: #777;
  }
  