/* LandingPage.css */



.LandingPage {
  font-family: "Georgia", serif;
  background-color: #ffffff; /* White background */
  color: #333; /* Font color */
  text-align: center;
  /* padding: 20px; */
  box-sizing: border-box; /* Ensure padding and border are included in the width and height */
  background: #f6f4f2;
  padding-bottom: 0;
}

.LandingPage-header {
  /* margin-bottom: 20px; */
  padding: 20px;
  padding-bottom: 0;
}

.LandingPage-logo {
  width: 70vw; /* Adjust size */
  max-width: 400px; /* Ensure logo doesn’t get too large */
  height: auto; /* Maintain aspect ratio */
}

.LandingPage-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 0;
}

.LandingPage-titles{
  color: #dcc8b4 ;
  border-bottom: 3px solid #dcc8b4;
}

.LandingPage-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  flex-direction: row; /* Ensure text and image are side by side */

}

.LandingPage-text,
.LandingPage-image {
  flex: 1 1;
  padding: 20px;
}

.LandingPage-text {
  text-align: left; /* Align text to the left for better readability */
}


.LandingPage-text p {
  font-size: 14px;
  color: #777;
  line-height: 1.6;
}

.LandingPage-image {
  text-align: center; /* Center-align the image */
}

.LandingPage-image img {
  width: 60%;
  max-width: 500px; /* Adjust as needed */
  height: auto;
}


/* Existing styles remain the same */

.LandingPage-footer {
  background-color: #e6e6e6;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  padding: 0;
  margin: 0;
}

.footer-column {
  flex: 1;
  margin: 0 10px;
}


.footer-column p {
  font-size: 14px;
  line-height: 1.4;
  color: #999;
}

.expanded-section {
  /* margin-top: 40px;  */
  margin-bottom: 40px;
  text-align: left;
  display: block;
}

.expanded-section p {
  margin-bottom: 20px; /* Increase space between paragraphs */
  width: 100%; /* Ensure full-width paragraphs */
  
  color: #777;
  font-size: 14px;
  line-height: 1.6;
}

.LandingPage .expanded-section ol li {
  color: #777;
  font-size: 14px;
  line-height: 1.6;
}

.footer-column p a {
  color: #007bff ;
  text-decoration: none;
}

.footer-column p a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .LandingPage-section {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }

  .LandingPage-text,
  .LandingPage-image {
    width: 100%; /* Make sure each item takes full width */
    text-align: center; /* Center text */
  }

  .LandingPage-image img {
    width: 60%;
    max-width: 400px;
  }

  .LandingPage-logo {
    width: 80vw; /* Adjusts to 60% of the viewport width */
    height: auto; /* Maintain aspect ratio */
  }
  .footer-links {
   flex-direction: column;
  }
}

.whatsapp-link:hover {
  text-decoration: none;
}

.whatsapp-button {
  position: fixed; /* Fix the button in place */
  bottom: 20px; /* Distance from the bottom of the screen */
  right: 20px; /* Distance from the right of the screen */
  z-index: 1000; /* Ensure it is on top of other content */
}

.whatsapp-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366; /* WhatsApp green color */
  text-align: center;
  border-radius: 50%; /* Circular button */
  padding: 7px;
  color: white; /* Icon color */
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Optional shadow */
}

.whatsapp-button i {
  font-size: 30px; /* Size of the WhatsApp icon */
}

.footer-links {
  margin-top: 10px;
  display: flex;
  justify-content: center; /* Center the links horizontally */
}

.footer-links a {
  text-decoration: none;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 10px;
  color: #999;
  text-align: center;
}

.footer-link:hover {
  text-decoration: underline; /* Optional hover effect */
}