/* App.css */

:root {
  /*already implemented*/
  --body-background-color: #f6f4f2;
  --app-background-color: #fff; /* App background color */
  --header-color: #dcc8b4; /* golden color for h1-h6 */
  --secondary-text-color: #666666;
  --primary-border-color: #dcc8b4;
  --main-text-photo-color: #777; /*Photo and description*/
  
  --text-color: #666; /* Soft grayish-white for main text */
  --ad-text-color:  #666; /* Soft grayish-white for main text */
  /*Inputs*/
  --input-border-color: #ddd;
  --input-background-color: #f9f9f9;

  --photo-gallery-border:white;
  --photo-border-size: 0.5px;

  /*Icon*/
  --icon-color-reload: #b89d89;
  --icon-color-lighter: #dcc8b4;

  /* Button Gradient Variables */
  --gradient-color-start: #dcc8b4; /* light golden color */
  --gradient-color-end: #b89d89; /* darker shade */

  /* Button Hover Gradient Variables */
  --hover-gradient-color-start: #b89d89; /* darker shade */
  --hover-gradient-color-end: #dcc8b4; /* light golden color */

  --textarea-background: #fff;
  --textarea-color: #666;
  --textarea-border-color: #2f2b3b;
  --textarea-placeholder: #e0e0e0;

  --reminder-shadow: rgba(0, 0, 0, 0.2);
  --generic-box-shadow: rgba(0, 0, 0, 0.1);
}

/*Halloween*/
/* .secondary-theme {
  --body-background-color: #1e1b29;
--body-background-image: url("./assets/images/hall8.png");
--app-background-color: #3c3b4e;
--header-color: #f97e36;
--secondary-text-color: #c4a2a1;
--primary-border-color: #f97e36;
--main-text-photo-color: #777;

--text-color: #e0e0e0;

--photo-gallery-border: rgba(255, 165, 0, 0.8);
--photo-border-size: 0.1px;

--subtle-orange-glow: 0 0 5px rgba(255, 165, 0, 0.5), 0 0 10px rgba(255, 165, 0, 0.3);

--input-border-color: #4a495a;
--input-background-color: #2f2b3b;

--icon-color-reload: #f97e36;
--icon-color-lighter: #af5535;

--gradient-color-start: #af5535;
--gradient-color-end: #7a3e3a;

--hover-gradient-color-start: #7a3e3a;
--hover-gradient-color-end: #af5535;

--textarea-background: #2f2b3b;
--textarea-color: #e0e0e0;
--textarea-border-color: #4a495a;
--textarea-placeholder: #e0e0e0;

--reminder-shadow: rgba(255, 165, 0, 0.5);
--generic-box-shadow: rgba(255, 165, 0, 0.1);
} */
/*Christmass*/
.secondary-theme {
  --body-background-color: #386641; /* Deep green for a cozy and festive base */
  --body-background-image: url("./assets/images/x-mass2.jpg"); /* Festive Christmas background */

  --app-background-color: #f2e8cf; /* Soft, light cream to let your golden logo shine */
  --header-color: #bc4749; /* Bold, warm red to evoke Christmas ornaments and accents */
  --secondary-text-color: #f2e8cf; /* Light cream for secondary text */
  --primary-border-color: #6a994e; /* Muted green for a calming, earthy vibe */
  --main-text-photo-color: #f2e8cf; /* Soft cream for text and photo captions */

  --text-color: #f2e8cf; /* Keeping text consistent with soft, warm tones */
  --ad-text-color:  #f2e8cf; /* Soft grayish-white for main text */
  --text-color: #666; /* Soft grayish-white for main text */

  --photo-gallery-border: rgba(188, 71, 73, 0.8); /* Warm red border for a festive highlight */
  --photo-border-size: 0.2px; /* Subtle border size */

  --subtle-orange-glow: 0 0 5px rgba(188, 71, 73, 0.5),
    0 0 10px rgba(106, 153, 78, 0.3); /* Soft red and green glow for interactive elements */

  --input-border-color: #6a994e; /* Muted green for input borders */
  --input-background-color: #f2e8cf; /* Light cream for input backgrounds */

  --icon-color-reload: #bc4749; /* Bold red for icons */
  --icon-color-lighter: #a7c957; /* Light green for contrast on icons */

  --textarea-background: #f2e8cf; /* Warm cream for textareas */
  --textarea-color: #386641; /* Dark green for textarea text */
  --textarea-border-color: #6a994e; /* Muted green for textarea borders */
  --textarea-placeholder: #a7c957; /* Light green placeholder text for a cheerful touch */

  --reminder-shadow: rgba(188, 71, 73, 0.5); /* Soft red shadow for reminders */
  --generic-box-shadow: rgba(106, 153, 78, 0.2); /* Soft green shadow for elements */
  
}

/*Christmass*/


/* Apply a romantic background to the entire page */
body {
  background-size: cover;
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 17px;
  color: var(--secondary-text-color);
  font-family: "Montserrat", sans-serif !important;

  background-color: var(--body-background-color);
  background-image: var(--body-background-image);

  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent tiling */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Libre Baskerville", serif;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  font-size: 25px; /* Default size */
  font-weight: 400;
  color: var(--primary-border-color);
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 20px;
}

/* Centered container with subtle shadow and rounded corners */
.App {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  /* background: rgba(255, 255, 255, 0.8); */
  border-radius: 12px;
  box-shadow: 0 6px 12px var(--reminder-shadow);
  background: var(--app-background-color);
}

@media (max-width: 768px) {
  .App {
    margin: 20px; /* Adjust the margin for mobile devices */
    border-radius: 6px;
  }
  .App-logo {
    width: 80vw; /* Adjusts to 60% of the viewport width */
    height: auto; /* Maintain aspect ratio */
  }
}

.App-header {
  text-align: center;
  width: 100%;
}

.App-logo {
  width: 80vw; /* Adjusts to 60% of the viewport width */
  max-width: 400px; /* Ensures the logo doesn’t get too large */
  height: auto; /* Maintain aspect ratio */
}

h1 {
  margin-top: 0 !important ;
}

h2 {
  border-bottom: 3px solid var(--primary-border-color);
  padding-bottom: 10px;
}

input[type="text"],
input[type="file"] {
  display: block;
  width: calc(100% - 22px);
  margin: 10px auto;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid var(--input-border-color);
  font-size: 16px;
  background: var(--input-background-color);
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-size: 16px;
  color: var(--text-color);
}

input[type="text"]::placeholder {
  color: var(--text-color); /* Burnt orange for placeholder text */
  /* opacity: 0.8; */
}

textarea {
  background-color: var(--textarea-background);
  color: var(--text-color);
  border: 1px solid var(--input-border-color) !important;
}

textarea::placeholder {
  color: var(--text-color);
  opacity: 0.8;
}



button {
  display: block;
  width: 100%;
  padding: 12px;
  background: linear-gradient(
    135deg,
    var(--gradient-color-start),
    var(--gradient-color-end)
  );
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
}

button:hover {
  background: linear-gradient(
    135deg,
    var(--hover-gradient-color-start),
    var(--hover-gradient-color-end)
  );
  transform: scale(1.02); /* Slight zoom effect */
}

/* Flexbox gallery with spacing and center alignment */
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Individual gallery item styling */
.gallery div {
  text-align: center;
  width: 220px;
  /* border: 1px solid #ddd; */
  /* background: #fff; */
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 6px 12px var(--generic-box-shadow);
}

/* Romantic image styling */
.gallery img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Uploaded by text with a lighter color */
.photo-container p {
  margin: 10px 0 0;
  color: var(--main-text-photo-color);
  font-size: 14px;
}

/* Loader and loading backdrop */
.loading-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it covers the entire app */
}

.loading-box {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 15px var(--generic-box-shadow);
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #88304e; /* Match loader color with main theme */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

.loading-box p {
  font-size: 16px;
  color: var(--main-text-photo-color);
  margin: 0;

}

/* App.css or your preferred CSS file */
.event-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
}

/* src/components/CoverImage.css */
.cover-image-container {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  margin-bottom: 10px;
  max-height: 600px;
  border-radius: 10px; /* Match the image's border radius */
  display: flex; /* Ensure content stays centered */
  justify-content: center;
  align-items: center;
}

.event-cover {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  max-width: 600px; 
  border-radius: 10px; /* Optional: Add rounded corners */
  object-position: center top; /* Adjust this to control the focal point */
  box-shadow: var(--subtle-orange-glow); /* Using the variable for the glow effect */
}
/* Media Queries for Additional Responsiveness */

/* Media Queries for Responsive Heights */

@media (max-width: 480px) {
  /* Mobile view */
  .cover-image-container {
    height: auto; /* Let the container adjust automatically */
    max-height: 60vh; /* Max height is half of the viewport height */
    /* max-height: 600px; 
    max-height: 55vh;  */

  }

  .event-cover {
    height: auto; /* Allow the image to maintain its aspect ratio */
  }
}

/* .event-cover {
  width: 80%; 
  max-width: 600px; 
  border-radius: 10px; 
  margin-bottom: 10px;
} */

.event-message {
  font-size: 1.2em; /* Adjust font size */
  color: #333; /* Adjust text color */
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0; /* Optional background for better contrast */
  font-family: "Courier Prime", Courier, monospace !important; /* For body text */
}

.photo-container {
  position: relative;
  display: inline-block;
}

.download-icon {
  position: absolute;
  bottom: 3px;
  right: 35px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.download-icon:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/*Probably Download Album*/

/*Probably Download Album*/

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9; /* Light background to contrast the spinner */
  flex-direction: column;
}

.loader-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  border-top: 5px solid #88304e;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  font-family: "Georgia", serif;
  font-size: 1.5rem;
  color: var(--main-text-photo-color);
  margin-top: 20px;
}

.photo-message {
  width: calc(100% - 22px);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 15px;
  resize: none;
  height: 100px;
  margin-top: 10px;
  box-shadow: 0 2px 5px var(--generic-box-shadow); /* Light shadow for depth */
  line-height: 1.5;
  min-height: calc(1.5em * 1); /* Adjust for 1 line height */
  max-height: calc(1.5em * 2); /* Adjust for 2 lines height (or as needed) */
  color: var(--text-color);
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.photo-message-text {
  line-height: 1.2;
  font-size: 14px;
  margin-top: 8px;
  color: #333;
  color: #666666;
  margin-bottom: 22px !important;
  /* border-bottom: 1px solid var(--primary-border-color);
width: 100%;
padding-bottom: 8px; */
}

/* Slideshow Styles */
.slideshow-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  /* background-color: rgba(0, 0, 0, 0.8); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease;
  touch-action: none; /* Prevent touch gestures that cause movement */
  overflow: hidden; /* Prevent scrolling */
}

.slideshow-modal.active {
  opacity: 1;
}

.slideshow-image {
  max-width: 90%;
  max-height: 90%;
  transition: opacity 0.5s ease;
}

.slideshow-video {
  max-width: 80%;
  max-height: 80%;
  transition: opacity 0.5s ease;
}

.slideshow-control {
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #000;
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.slideshow-control.prev {
  left: 20px;
}

.slideshow-control.next {
  right: 20px;
}

.close-slideshow {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #000;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slideshow-toggle {
  margin: 20px auto;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: calc(100% - 22px) !important;
}

.photo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: hidden;
  touch-action: none; /* Prevent touch gestures that cause movement */
}

.overlay-photo {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.7);
  transition: transform 0.3s ease;
}

.overlay-media {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.7);
  transition: transform 0.3s ease;
}
/* .overlay-photo:hover {
  transform: scale(1.05);
} */

.photo-overlay:active {
  cursor: pointer;
}
.overlay-close {
  position: absolute;
  top: 20px;
  right: 20px;

  color: white;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  z-index: 10000;

  font-size: 24px;
  transition: background 0.3s, transform 0.2s;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  width: 40px; /* Ensures equal width */
  height: 40px; /* Ensures equal height */
  border-radius: 50%; /* This will now form a perfect circle */
}

.overlay-close:hover {
  background-color: rgba(255, 255, 255, 0.7);
  color: #000;
}

.overlay-close:active {
  transform: scale(0.95);
}

/*Download Album*/
.download-album-container {
  text-align: center;
}

.loading-spinner {
  border: 4px solid var(--generic-box-shadow);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  margin-top: 10px;
}

/*text to position upper right corner of the photo*/
/* Styles for the photo container */
.overlay-uploader-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 10;
}

.overlay-uploader-name p {
  color: white;
  font-size: 14px;
  margin: 10px 0 0;
}

/* Styles for the close button */
.slideshow-text-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.slideshow-text-overlay p {
  color: white; /* Text color */
}

/*emoji picker*/
.upload-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: none;
  color: #333;
  cursor: pointer;
  width: auto; /* Allow button to resize based on content */
  height: auto; /* Allow button to resize based on content */
  min-width: 36px;
  min-height: 36px;
  padding: 0;
  margin: 0;
}

.icon-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.icon-button i {
  font-size: 16px;
}

.upload-container button {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis;
  padding: 12px;
  /* border: 1px solid #ccc;  */
  background-color: #fff;
  font-size: 16px;
}

@media (max-width: 405px) {
  .upload-container button {
    font-size: 13px;
  }
  .slideshow-container .slideshow-toggle {
    font-size: 13px;
  }
}

.pause-slideshow {
  position: absolute;
  top: 10px;
  right: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #000;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pause-slideshow i {
  color: white;
}

/*TEST 17-09*/

/* Flexbox gallery with spacing and center alignment */
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Individual gallery item styling */
.gallery div {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
  box-shadow: 0 6px 12px var(--generic-box-shadow);
  box-sizing: border-box; /* Ensures padding and border are included in width */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
}

/* Photo container styling */
.photo-container {
  width: 100%; /* Make sure the container takes up full width of its parent */
  display: flex;
  flex-direction: column; /* Stack image and text vertically */
  align-items: center; /* Center the image and text horizontally */
  justify-content: flex-start; /* Align the content to the top of the container */
  overflow: hidden; /* Ensure that any overflow is hidden */
}

.photo-container {
  position: relative;
}

/* Romantic image styling */
.gallery-photo {
  width: 100%; /* Make image responsive to the container */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure image doesn’t exceed container’s width */
  border-radius: 10px;
  /* margin-bottom: 10px;  */
}

/* Image styling */
.gallery-photo {
  max-height: 190px; /* Set the maximum height of the image */
  object-fit: cover; /* Crop the image to fit within the defined area */
  object-position: center; /* Center the image within the container */
}

.gallery-video {
  max-height: 190px; /* Set the maximum height of the video */

  object-position: center; /* Center the video within the container */
  margin-bottom: 10px; /* Space between items */
  display: block; /* Ensure the video takes up the block-level space */

  position: absolute; /* Position the video absolutely */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */
  /* border-radius: 10px;  */
  object-fit: cover; /* Ensure the video covers the entire area */
}

/* For large screens (3 items per row) */
@media (min-width: 1024px) {
  .gallery div {
    width: calc(33.33% - 20px); /* 3 items per row, with gap included */
  }
}

/* For medium screens (tablet, 2 items per row) */
@media (min-width: 768px) and (max-width: 1023px) {
  .gallery div {
    width: calc(50% - 20px); /* 2 items per row, with gap included */
  }
}

/* For small screens (mobile, 2 items per row) */
@media (max-width: 767px) {
  .gallery div {
    width: calc(50% - 20px); /* 2 items per row, with gap included */
  }
}

/* For very small screens (up to 480px) */
@media (max-width: 480px) {
  .gallery div {
    width: calc(50% - 20px); /* 2 items per row, with gap included */
  }
  .photo-message-text {
    font-size: 11px;
    margin-bottom: 0;
  }
  .photo-container p {
    font-size: 12px;
    margin: 5px 0 0;
  }
  .heart-icon {
    font-size: 20px !important;
  }
}

.trash-icon {
  position: absolute;
  bottom: 3px;
  right: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.trash-icon:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.likes-counter {
  /* margin: 10px 0 0; */
  color: var(--main-text-photo-color);
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
}

.like-container {
  display: flex;
  border: none !important;
  box-shadow: none !important;
  /* position:absolute;
  bottom: -10px;
  left: 10px; */
  /* align-items: center;
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
  flex-direction: row !important; */
}

.heart-icon {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: #ff3040;
  cursor: pointer;
  font-size: 24px;
  /* color: violet; */
}

.heart-icon::after {
  content: attr(data-likes);
  margin-left: 5px;
  font-size: 9px;
  display: none; /* Initially hidden */
}

.heart-icon::after {
  content: attr(data-likes);
  margin-left: 5px;
  font-size: 9px;
  display: none; /* Initially hidden */
}

.heart-icon[data-likes]:not([data-likes="0"])::after {
  display: inline; /* Show only when data-likes is greater than 0 */
  color: white;
}

.heart-icon[data-likes="me gusta"]::after {
  word-spacing: 2px;
}
/* .likes-counter {
  font-size: 14px;
margin-top: 12px;
} */

/* For very small screens (up to 480px) */
@media (max-width: 350px) {
  .like-container {
    flex-direction: column !important;
  }
  .likes-counter {
    margin-right: 10px;
    font-size: 12px;
    display: none;
  }
  .heart-icon {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.slideshow-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}

.icon-button-reload i:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.icon-button-reload {
  display: flex; /* Use flexbox for centering the icon */
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: none; /* Remove border */
  color: var(--icon-color-reload); /* Icon color */
  cursor: pointer;
  width: auto; /* Allow button to resize based on content */
  height: auto; /* Allow button to resize based on content */
  min-width: 36px;
  min-height: 36px;
  padding: 0;
  margin: 0;
}

.icon-button-reload:disabled {
  cursor: not-allowed;
  color: #ccc;
}

.reminder-banner {
  /* background-color: #ffeb3b;  */
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  position: relative;
  /* box-shadow: 0 2px 5px var(--reminder-shadow); */
  max-width: 600px;
  margin: auto;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px var(--reminder-shadow);

  box-sizing: border-box;
}

.reminder-banner p {
  font-size: 14px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 12px;
  line-height: 1.5;
  /* color: #777; */
  color: var(--text-color);
}

.copy-link-button {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  color: #fff; /* Text color */
  cursor: pointer;
  width: calc(100% - 22px);
}

.copy-link-button:hover {
  transform: scale(1); /* Cancel the scaling effect */
}

.close-banner-button {
  position: absolute; /* Positioning the close button */
  top: 5px;
  right: 10px;
  background: none; /* Remove background */
  border: none; /* Remove border */
  color: #999; /* Light color for visibility */
  font-size: 20px; /* Increase font size */
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .reminder-banner {
    padding: 8px;
  }

  .copy-link-button {
    width: calc(100% - 22px);
    margin-top: 5px; /* Add margin above for spacing */
  }

  .close-banner-button {
    font-size: 18px; /* Slightly smaller close button on small screens */
  }
  .copy-link-button {
    font-size: 12px;
  }
}

@media (max-width: 580px) {
  .reminder-banner {
    font-size: 14px; /* Smaller font size for very small screens */
    /* max-width: 400px; */
  }

  .close-banner-button {
    font-size: 16px; /* Adjust close button size */
  }
  .reminder-banner p {
    font-size: 12px;
  }
  .copy-link-button {
    font-size: 12px;
  }
}

/*HEART ANIMATION*/
.heart-animation-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 24px;
  height: 24px;
  pointer-events: none; /* So the hearts don’t block clicks */
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.floating-heart {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);
  opacity: 0;
  font-size: 16px;
  color: red;
  animation: float 1s ease forwards; /* Animation for the heart */
  pointer-events: none;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

/* @keyframes floatUp {
  0% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50px) scale(1.5);
    opacity: 0;
  }
} */

@keyframes float {
  0% {
    transform: translateY(0); /* Start at the original position */
    opacity: 1; /* Fully visible */
  }

  100% {
    transform: translateY(-50px); /* Move up more */
    opacity: 0; /* Fade out */
  }
}

/* .floating-heart:nth-child(2) {
  animation: float2 1s ease forwards; 
}

@keyframes float2 {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-40px); 
    opacity: 0;
  }
} */

/*06/10 FOR AUTO SLIDE PHOTO WHEN CLICKED*/
.icon-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.icon-button {
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s, color 0.3s;
  color: #ccc; /* Dark text color for icons */
  -webkit-tap-highlight-color: transparent !important; /* Removes tap highlight */
  touch-action: manipulation; /* Prevent tap highlight effect on mobile */
  outline: none; /* Removes default focus outline */
}

.icon-button.active {
  /* background-color: #4CAF50;  */
  color: var(--icon-color-reload); /* Icon color for active state */
}

.icon-button i {
  font-size: 24px; /* Adjust icon size */
}

.icon-button:focus {
  outline: none; /* Removes default focus outline */
}
/*0810*/

/* Flexbox gallery with spacing and center alignment */
.gallery {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 2px !important;
  justify-content: center !important;
  padding: 0 !important; /* No padding in the parent gallery */
}

/* Individual gallery item styling */
.gallery div {
  text-align: center !important;
  width: calc(33.33% - 10px) !important; /* 3 items per row on larger screens */
  height: auto !important; /* Set height to auto for flexibility */
  padding: 0 !important; /* Remove padding */
  border-radius: 0 !important; /* Remove border-radius for clean edges */
  box-shadow: 0 6px 12px var(--generic-box-shadow) !important;
  overflow: visible !important; /* Prevents image overflow */
  margin: 0 !important; /* Ensure no margin around the square */
  border: none !important;
}

/* Ensure image remains square */
.gallery img {
  width: 100% !important;
  height: 220px !important; /* Fixed height for consistency */
  object-fit: cover !important; /* Crop the image to fit the square */
  object-position: center !important; /* Center the cropped area */
  display: block !important; /* Remove space below the image caused by inline elements */
  margin: 0 !important; /* Reset any margin */
  padding: 0 !important; /* Reset any padding */
  border-radius: 0 !important; /* Remove border-radius for square images */
  border: var(--photo-border-size) solid var(--photo-gallery-border) !important;
}

/* Additional styles you added */
.photo-container p {
  display: none !important;
}

.photo-container img {
  border-radius: 0 !important; /* Ensure no rounding */
}

.photo-container {
  box-shadow: none !important;
}

.photo-message-text {
  display: none !important;
}

/* .like-container {
  display: none !important;
} */

/* Responsive behavior for mobile devices (2 items per row and square) */
@media (max-width: 768px) {
  .gallery div {
    width: calc(
      50% - 15px
    ) !important; /* Make each square 50% of the container width minus the gap */
    height: auto !important; /* Allow height to adjust */
  }
}

/* Additional adjustments for various screen sizes */
@media (min-width: 440px) {
  .gallery div {
    width: calc(
      33.33% - 10px
    ) !important; /* 3 items per row on larger screens */
    height: auto !important; /* Allow height to be flexible */
  }
}

/*ACTION ITEM PHOTO OPTIONS*/
.action-icon {
  position: absolute;
  bottom: 4px;
  right: 10px;
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px;
  border-radius: 50%;
  font-size: 18px;
  transition: background-color 0.3s ease;
  width: 15px !important;
  height: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; /* Higher than the dropdown */
}

.photo-dropdown-menu {
  position: absolute;
  bottom: 20px;
  right: 10;
  display: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* padding: 10px; */
  width: 100% !important; /* Ensure the dropdown menu takes the full width */
  min-width: 100%; /* Minimum width for usability */
  padding: 0; /* Remove any padding to ensure items fill the width */
  pointer-events: none; /* Prevent interaction when hidden */
  opacity: 0;
  z-index: 1;
  background-color: transparent !important;
  /* transition: left 0.4s ease, opacity 0.4s ease !important;  */
  padding: 0;
  transition: opacity 0.4s ease;
  box-sizing: none !important;
  max-height: 0px;
}

.photo-dropdown-menu.show {
  top: 0; /* Keep adjusted for dropdown position */
  opacity: 1; /* Fade in */
  pointer-events: auto; /* Allow interaction when visible */
  display: block;
  animation: slideIn 0.3s ease-in-out;
  /* background-color: transparent !important; */
}

.photo-dropdown-item:nth-child(odd) {
  background-color: rgba(249, 249, 249, 0.8);
  /* border-left: 4px solid var(--icon-color-reload);  */
}

.photo-dropdown-item:first-child {
  border-left: 4px solid var(--icon-color-reload); /* Only first item gets the border */
}

.photo-dropdown-item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.8); /* White for even items */

  border-bottom: none;
}

.photo-dropdown-item:nth-child(odd),
.photo-dropdown-item:nth-child(even) {
  border-left: none; /* Remove left border from all other items */
}
/* Slide-in animation */
@keyframes slideIn {
  from {
    transform: translateY(0); /* Start slightly above */
    opacity: 0;
  }
  to {
    transform: translateY(0); /* Slide down to position */
    opacity: 1;
  }
}

.photo-dropdown-item {
  cursor: pointer;
  border-bottom: 1x solid var(--generic-box-shadow); /* Add a border to each item */
color: #666;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-size: 13px;
  text-align: left !important; /* Align text to the left */
  display: block; /* Ensure items are block elements */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding in width calculation */
  padding: 6px 10px; /* Comfortable padding for tap targets */
  padding: 8px;
  cursor: pointer;
  /* transition: background-color 0.3s ease, box-shadow 0.3s ease; 
  transition: background-color 0.2s; */
}

@media (max-width: 600px) {
  .action-icon {
    font-size: 16px;
    bottom: 5px;
    padding: 3px;
  }
  .photo-dropdown-item {
    font-size: 12px;
    padding: 5px 7px; /* Comfortable padding for tap targets */
  }
}
.photo-dropdown-item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px var(--generic-box-shadow);
  font-weight: 400;
}

/* Highlight border on hover */
.photo-dropdown-item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px var(--generic-box-shadow);
  font-weight: 400;
  border-left: 4px solid var(--icon-color-reload); /* Add left border on hover */
}

.photo-dropdown-menu ul {
  list-style: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  width: 100%; /* Ensure it takes the full width of the dropdown */
}

/*ACTION ITEM END*/

/* .photo-dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.1); 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  font-weight: 400; 
} */

/*ACTION ITEM END*/

/**/
.icon-with-text {
  position: relative;
}

.icon-with-text::after {
  /* content: "fotógrafo"; */
  content: attr(data-text); /* Dynamically use text from data-text */

  position: absolute;
  top: -7px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
  font-family: "Courier New", Courier, monospace;
  font-size: 9px;
  white-space: nowrap;
  text-transform: uppercase;
}

.icon-with-text-1 {
  position: relative;
}

.icon-with-text-1::after {
  /* content: "invitados1"; */
  content: attr(data-text);
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
  font-family: "Courier New", Courier, monospace;
  font-size: 9px;
  white-space: nowrap;
  text-transform: uppercase;
}

.icon-with-text-2 {
  position: relative;
}

.icon-with-text-2::after {
  content: "video";
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
  font-family: "Courier New", Courier, monospace;
  font-size: 9px;
  white-space: nowrap;
  text-transform: uppercase;
}

.icon-with-text-3 {
  position: relative;
}

.icon-with-text-3::after {
  content: "Guestbook";
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
  font-family: "Courier New", Courier, monospace;
  font-size: 9px;
  white-space: nowrap;
  text-transform: uppercase;
}

/*GUESTBOOK MODAL*/
/* Modal overlay background */
.guestbook-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; /* Centers the modal vertically and horizontally */
  z-index: 9999;
  touch-action: none; /* Prevent touch gestures inside the modal */
  overflow: hidden; /* Prevent inner modal scrolling */
}

/* Modal content styling */
.guestbook-modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  max-width: 500px;
  width: 50%;
  margin: auto;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2);
  position: relative; /* For positioning the close button */
  touch-action: none; /* Prevent touch gestures inside the modal */
  overflow: hidden; /* Prevent inner modal scrolling */
}

/* Close button styling */
.guestbook-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #000;
  width: 30px; /* Fixed width for circle */
  height: 30px; /* Fixed height for circle */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Textarea styling */
.guestbook-textarea {
  width: calc(100% - 22px);
  height: 100px; /* Adjust height as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
  resize: none; /* Prevent resizing */
}

/* Submit button styling */
.guestbook-submit-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
}

/* Hover effect for the submit button */
.guestbook-submit-button:hover {
  background-color: #45a049; /* Darker green */
}

/* Modal content text */
.guestbook-modal-content h2 {
  margin-top: 0;
  font-size: 22px;
}

.guestbook-modal-content p {
  font-size: 13px;
  line-height: 1.5;
  color: #555;
  margin: 10px 0 0;
}

@media screen and (max-width: 600px) {
  .guestbook-modal-content {
    width: 70%;
  }

  /* Modal content text */
  .guestbook-modal-content h2 {
    font-size: 20px;
  }

  .guestbook-modal-content p {
    font-size: 11px;    
  }
  .guestbook__title {
    margin-bottom: 5px !important; /* Space below the title */
  }
  
}

/* Override hover styles to eliminate any unwanted effects */
.guestbook-close-button:hover {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly darker on hover */
}

/* Guestbook message styles */

.guestbook-message {
  /* display: flex; */
  /* flex-direction: column; */
  margin-bottom: 7px; /* Reduced space between messages */
  padding: 4px; /* Reduced padding for each message */
  background-color: #fff; /* White background for messages */
  border: 1px solid #e0e0e0; /* Slightly darker border for messages */
  border-radius: 5px; /* More rounded corners */
  box-shadow: 0 1px 2px var(--generic-box-shadow); /* Softer shadow */
}

.guestbook-message p,
.message-timestamp {
  margin: 5px;
}

.message-text {
  margin: auto; /* Remove default margin from paragraphs */
}

.guestbook-section {
  position: relative; /* Allows positioning of the button within this section */
  /* max-height: 120px;  */
  overflow-y: auto; /* Enables vertical scrolling */
  border: 1px solid #d1d1d1; /* Lighter border */
  padding: 10px; /* Padding */
  margin-top: 20px; /* Space above the section */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background color */
  box-shadow: 0 2px 4px var(--generic-box-shadow); /* Soft shadow for the section */
  width: calc(100% - 22px) !important;
  max-height: 80vh; /* Limit height to 80% of viewport height */
  overflow: hidden; /* Prevent overflow affecting the layout */
}
.guestbook-add-icon {
  position: absolute; /* Position it absolutely within the parent */
  top: 10px; /* Space from the top */
  right: 10px; /* Space from the right */
  color: var(--icon-color-lighter); /* Change color as needed */
  cursor: pointer; /* Pointer cursor on hover */
}

/* Optional: Add hover effect */
/* .guestbook-add-icon:hover {
  color: #45a049; 
} */

.guestbook__title {
  font-size: 24px; /* Default font size */
  margin-bottom: 15px; /* Space below the title */
  margin-top: 5px;
}
.messages-container {
  max-height: 90px; /* Allow space for the icon and title */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-top: 10px; /* Add padding to ensure scrolling starts below the title */
}
/* Media query for responsive title */
@media screen and (max-width: 600px) {
  .guestbook__title {
    font-size: 18px; /* Smaller font size for mobile */
  }
  .guestbook-add-icon {
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .messages-container .guestbook-message p {
    font-size: 13px !important; /* Set font size to 10px on mobile */
  }
  .messages-container .message-timestamp {
    font-size: 11px !important; /* Set font size to 10px on mobile */
  }
  .message-text {
   font-size: 14px;
    
  }
}


.guestbook-message p {
  font-size: 16px !important; /* Set font size to 10px on mobile */
}
.message-text {
  margin: 0; /* Remove margin to eliminate extra space */
  
}

.message-timestamp {
  display: block; /* Ensure it takes a new line */
  font-size: 16px; /* Smaller font size for the timestamp */
  color: #888; /* Lighter color for the timestamp */
  margin-top: 2px; /* Reduced space above the timestamp */
}

.message-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.delete-icon {
  cursor: pointer;
  color: var(--icon-color-lighter); /* Change color as needed */
  margin-right: 5px;
  font-size: 14px;
}

.action-option {
  font-size: 11px;
}
