:root {
    /*already implemented*/
    /* --body-background-color: #f6f4f2; */
    /* --app-background-color: #fff;  */
    --header-color: #dcc8b4; /* golden color for h1-h6 */
   
    /*already implemented*/
    --admin-link-background-color: #dcc8b4; 
  }
  
  .admin-container {
    padding: 40px;
    text-align: center;
    background-color: #f4f4f9;
    min-height: 100vh;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .password-form {
    display: inline-block;
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  .password-form label {
    display: block;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .password-form input {
    font-family: "Arial", sans-serif;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    width: calc(100% - 22px);
  }
  
  .submit-button {
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .admin-links {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .admin-link {
    font-family: "Arial", sans-serif;
    background-color: var(--admin-link-background-color); /* Set default background */
    color: white;
    text-decoration: none;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 18px;
    transition: background 0.3s, transform 0.2s;
  }
  .admin-link:hover{
    background-color: #b89d89; /* Slightly different color without gradient */
    transform: scale(1.02); /* Slight zoom effect */  }
 
  