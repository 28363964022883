/* Modal overlay background */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; /* Centers the modal vertically and horizontally */
  z-index: 9999;
}

/* Modal content styling */
.modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  max-width: 500px;
  width: 50%;
  margin: auto;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2);
  position: relative; /* For positioning the close button */
}


/* Close button styling */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #000;
  width: 30px; /* Fixed width for circle */
  height: 30px; /* Fixed height for circle */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Footer link styling */
.modal-link {
  display: inline-block; /* Ensures the width is only as wide as the text content */
  margin: 10px auto;
  font-size: 14px;
  color: #333;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: var(--ad-text-color) !important; /* Lighter color for rights text */
}

/* Modal content text */
.modal-content h2 {
  margin-top: 0;
  font-size: 22px;
}

.modal-content p {
  font-size: 13px;
  line-height: 1.5;
  color: #555;
  margin: 10px 0 0;
}


@media screen and (max-width: 600px) {
  .modal-content {
    width: 70%;
  }
  /* Modal content text */
.modal-content h2{
  font-size: 20px;
}
  .modal-content p{
    font-size: 11px;
  }
}


.modal-link {
  display: flex; /* Inline-flex will only take up the space needed */
  font-size: 14px;
  color: #333;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  text-align: center;
  justify-content: center;
  width: auto;
  margin: 10px auto;

}

/* Override hover styles to eliminate any unwanted effects */
.modal-link:hover {
  text-decoration: underline !important; /* Keep underline on hover, or change to 'none' if you want to remove it */
  color: #333; /* Ensure color stays the same */
  background: none !important; /* Remove any hover background */
  border: none !important; /* Ensure no borders appear */
  transform: none; /* Slight zoom effect */
}
