.album-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
  padding: 20px;
}

.album-not-found h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.album-not-found p {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 30px;
}

.album-not-found button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.album-not-found button:hover {
  background-color: #0056b3;
}