.TemplatePage {
    font-family: "Georgia", serif;
    background-color: #f6f4f2; /* Light background */
    color: #333; /* Font color */
    text-align: center;
    box-sizing: border-box; /* Include padding and border in width/height */
    padding-bottom: 0;
    box-sizing: border-box;
  }
  
  .TemplatePage-header {
    padding: 20px;
    padding-bottom: 0;
    width: 70vw; /* Responsive width */
    max-width: 400px; /* Max size for the header */
    height: auto; /* Maintain aspect ratio */
    margin: auto;
  }
  
  .TemplatePage-content {
    max-width: 850px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .TemplatePage h1 {
    text-align: center;
    margin-bottom: 40px;
    
  }
  
  .TemplatePage-section {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border: 1px solid #ccc; /* Light border */

  }
  
  .TemplatePage-section h2 {
    margin-bottom: 10px;
    
  }
  
  .TemplatePage-section p {
    margin: 0;
    font-size: 14px;
    color: #777;
    line-height: 1.6;
  }
  
  .template-gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Wrap items on smaller screens */
    gap: 20px; /* Space between template items */
  }
  
  .template-item {
    flex: 1 1 250px; /* Flexible item size */
    max-width: 250px; /* Max width for items */
    box-sizing: border-box; /* Include padding in width calculations */
    border: 1px solid #ccc; /* Light border */
    padding: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .template-item h3{
    color: #dcc8b4 !important;
  }
  .template-image {
    width: 100%; /* Responsive image */
    height: auto;
  }
  
  .download-button {
    background-color: #4CAF50; /* Green button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .download-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .TemplatePage-footer {
    text-align: center;
    background-color: #e6e6e6;
    border-top: 1px solid #ddd;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
    margin: 0;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .TemplatePage-header {
      padding: 20px;
      padding-bottom: 0;
    }
  
    .TemplatePage-content {
      padding: 20px;
    }
  
    .TemplatePage-section h2 {
      font-size: 1.25rem;
    }
  
    .TemplatePage-section p {
      font-size: 0.9rem;
    }
  
    .template-item {
      flex: 1 1 100%; /* Full width for mobile devices */
      max-width: 100%; /* Allow full width */
    }
  }
  
  @media (max-width: 480px) {
    .download-button {
      padding: 10px 15px; /* Adjust button padding on small screens */
    }
  }
  

  .access-templates{
    max-width: 400px;
    margin: auto;
    margin: 10px auto;
  }

  .password-input{
    
      display: block;
      width: calc(100% - 22px);
      margin: 10px auto;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid var(--input-border-color);
      font-size: 16px;
      background: var(--input-background-color);
      font-family: "Montserrat", sans-serif !important;
      font-weight: 300;
      font-size: 16px;
    
  }
.password-prompt{
    max-width: 800px;
    margin: auto;
}

.LandingPage-image img {
  width: 60%;
  max-width: 400px;
}

.TemplatePage-header {
  width: 80vw; /* Adjusts to 60% of the viewport width */
  height: auto; /* Maintain aspect ratio */
}

.download-link {
  color: #b89d89; /* Use the color from the palette */
  font-size: 0.9rem;
  font-weight: 300;
  text-decoration: none;
  transition: color 0.3s; /* Keep the transition for hover effect */
  gap:1px;
}

.download-link:hover {
  color: #dcc8b4; /* Change the color on hover */
  text-decoration: underline; /* Add underline on hover for visual feedback */
}

.model-canvas{
  padding-left: 15px;
  text-align: left;
 
}

.template-item p {
  margin-bottom: 5px;
}



.use-cases {
  flex: 1 1 250px; /* Flexible item size */
  max-width: 450px; /* Max width for items */
  box-sizing: border-box; /* Include padding in width calculations */
  border: 1px solid #ccc; /* Light border */
  padding: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}
.use-cases h3 {
  margin-top:0;

}

.slideshow {
  background: linear-gradient(135deg, #b89d89, #dcc8b4); /* Gradiente en tonos dorados y cálidos */
  height: 300px; /* Ajusta la altura según tus necesidades */
  color: white; /* Cambia el color del texto si es necesario */
  padding: 20px; /* Añade espacio interno */
  border-radius: 8px; /* Bordes redondeados, opcional */
  display: flex; /* Permite centrar el contenido */
  flex-direction: column; /* Apila el contenido verticalmente */
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  text-align: center; /* Centra el texto */
  
}

.mc-dj {
  background: linear-gradient(135deg, #b89d89, #dcc8b4); /* Gradiente en tonos dorados y cálidos */
  height: 300px; /* Ajusta la altura según tus necesidades */
  color: white; /* Cambia el color del texto si es necesario */
  padding: 20px; /* Añade espacio interno */
  border-radius: 8px; /* Bordes redondeados, opcional */
  display: flex; /* Permite centrar el contenido */
  flex-direction: column; /* Apila el contenido verticalmente */
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  text-align: center; /* Centra el texto */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Sombra opcional para darle profundidad */
}

.cabina {
  background: linear-gradient(135deg, #b89d89, #dcc8b4);
  height: 300px; /* Ajusta la altura según tus necesidades */
  color: white; /* Cambia el color del texto si es necesario */
  padding: 20px; /* Añade espacio interno */
  border-radius: 8px; /* Bordes redondeados, opcional */
  display: flex; /* Permite centrar el contenido */
  flex-direction: column; /* Apila el contenido verticalmente */
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  text-align: center; /* Centra el texto */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Sombra opcional para darle profundidad */
}

.use-cases p {
  color: #4e3b2b; /* A dark brown that complements the gold tones */
  font-weight: 300;
}

.use-cases h3{
  color: #4b3c31; /* A rich dark brown for strong contrast */

}

.TemplatePage-content h2{
  color: #dcc8b4 ;
  border-bottom: 3px solid #dcc8b4;
}