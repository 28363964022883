.PreguntasPage {
  font-family: "Georgia", serif;
  background-color: #ffffff; /* White background */
  color: #333; /* Font color */
  text-align: center;
  /* padding: 20px; */
  box-sizing: border-box; /* Ensure padding and border are included in the width and height */
  background: #f6f4f2;
  padding-bottom: 0;
}

.PreguntasPage-header {
  padding: 20px;
  padding-bottom: 0;
  width: 70vw; /* Adjust size */
  max-width: 400px; /* Ensure logo doesn’t get too large */
  height: auto; /* Maintain aspect ratio */
  margin: auto;
}

.PreguntasPage-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.PreguntasPage h1 {
  text-align: center;
  color: #dcc8b4 !important;
  /* font-size: 2.5rem; */

  margin-bottom: 40px;
}

.PreguntasPage-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.PreguntasPage-section h2 {
  margin-bottom: 10px;
}

.PreguntasPage-section p {
  margin: 0;

  font-size: 14px;
  color: #777;
  line-height: 1.6;
}

.PreguntasPage-footer {
  text-align: center;

  background-color: #e6e6e6;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .PreguntasPage-content {
    padding: 20px;
  }

  /* .PreguntasPage h1 {
    font-size: 2rem;
  } */

  .PreguntasPage-section h2 {
    font-size: 1.25rem;
  }

  .PreguntasPage-section p {
    font-size: 0.9rem;
  }
}

.PreguntasPage-section h2{
  color: #dcc8b4 ;
  border-bottom: 3px solid #dcc8b4;
}